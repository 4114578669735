import { createContext, ReactNode, useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { uniqBy } from '../../../util/arrayUtils';
import { VOID } from '../../../util/typeUtils';
import { LocatedAsset } from '../../api/LocatedAsset';
import { Microfence } from '../../api/Microfence';
import { MicrofencePair } from '../../api/MicrofencePair';
import { Navmesh, NavmeshArea, NavmeshLevel, NavmeshLink, NavmeshNode } from '../../api/Navmesh';
import { PortableAsset } from '../../api/PortableAsset';
import { ServiceConfig } from '../../api/ServiceConfig';
import { ToolType } from './EditTools';

export type CurrentEdit = |
{ type: 'located', asset: LocatedAsset } |
{ type: 'portable', asset: PortableAsset } |
{ type: 'link', asset: NavmeshLink & { uuid: undefined } } |
{ type: 'node', asset: NavmeshNode & { uuid: undefined } } |
{ type: 'level', asset: NavmeshLevel & { uuid: undefined } } |
{ type: 'area', asset: NavmeshArea & { uuid: undefined } } |
{ type: 'new link', asset: NavmeshLink & { uuid: undefined }, linkTo?: NavmeshNode } |
{ type: 'service config', asset: ServiceConfig & { uuid: undefined } } |
{ type: 'microfence', asset: (Microfence | MicrofencePair) & { uuid: undefined } };

export type BulkEdit = 'portable' | 'navmesh';

export type CurrentDeletion = LocatedAsset | PortableAsset | ServiceConfig | Microfence | MicrofencePair;

export type DeleteAssetByUuid = { uuid: string, delete: true, clientId: string, projectId: string, };
export type RevertChangeByUuid = { uuid: string, revert: true };
export type DeleteAssetById = { id: string, delete: true, clientId: string, projectId: string, };
export type RevertChangeById = { id: string, revert: true };

export type ClearChanges = { clearAll: true };

export type LocatedAssetEdit = LocatedAsset | DeleteAssetByUuid | undefined;
export type PortableAssetEdit = PortableAsset | DeleteAssetByUuid | undefined;
export type LocatedAssetsUpdate = LocatedAsset | LocatedAsset[] | DeleteAssetByUuid | RevertChangeByUuid | ClearChanges;
export type PortableAssetsUpdate = PortableAsset | PortableAsset[] | DeleteAssetByUuid | DeleteAssetByUuid[] | RevertChangeByUuid | ClearChanges;

export type MicrofenceEdit = Microfence | DeleteAssetById | undefined;
export type MicrofencePairEdit = MicrofencePair | DeleteAssetById | undefined;
export type MicrofenceUpdate = Microfence | Microfence[] | DeleteAssetById | RevertChangeById | ClearChanges;
export type MicrofencePairUpdate = MicrofencePair | MicrofencePair[] | DeleteAssetById | RevertChangeById | ClearChanges;

export type ServiceConfigEdit = ServiceConfig | DeleteAssetById | undefined;
export type ServiceConfigUpdate = ServiceConfig | DeleteAssetById | RevertChangeById | ClearChanges;

export type DeleteNavmeshItem = { id: number, delete: true };
export type RevertNavmeshChange = { id: number, revert: true };
export type NavmeshLinkEdit = NavmeshLink | DeleteNavmeshItem | RevertNavmeshChange | undefined;
export type NavmeshNodeEdit = NavmeshNode | DeleteNavmeshItem | RevertNavmeshChange | undefined;
export type NavmeshLevelEdit = NavmeshLevel | DeleteNavmeshItem | RevertNavmeshChange | undefined;
export type NavmeshAreaEdit = NavmeshArea | DeleteNavmeshItem | RevertNavmeshChange | undefined;
export type NavmeshLinkUpdate = NavmeshLink | NavmeshLink[] | DeleteNavmeshItem | RevertNavmeshChange | ClearChanges;
export type NavmeshNodeUpdate = NavmeshNode | NavmeshNode[] | DeleteNavmeshItem | RevertNavmeshChange | ClearChanges;
export type NavmeshLevelUpdate = NavmeshLevel | NavmeshLevel[] | DeleteNavmeshItem | RevertNavmeshChange | ClearChanges;
export type NavmeshAreaUpdate = NavmeshArea | NavmeshArea[] | DeleteNavmeshItem | RevertNavmeshChange | ClearChanges;
export type NavmeshUnitEdit = 'm' | 'ft' | undefined;

export type SetCurrentEditFn = (cur?: CurrentEdit) => CurrentEdit | undefined
export type FullOrPartialNavmesh = Partial<Navmesh> & Pick<Navmesh, 'links' | 'nodes' | 'clientId' | 'projectId'>;

export type MultiselectNavmeshLink = { 'clearAll': true } | { linkId: number } | { ids: number[], operation: 'add' | 'subtract' }

export type Editing = {
  tool: ToolType,
  setTool: (tool: ToolType) => void,
  locatedAssetEdits: Record<string, LocatedAssetEdit>,
  dispatchLocatedAssetEdit: (update: LocatedAssetsUpdate) => void,
  portableAssetEdits: Record<string, PortableAssetEdit>,
  dispatchPortableAssetEdit: (update: PortableAssetsUpdate) => void,
  navmeshLinkEdits: Record<string, NavmeshLinkEdit>,
  dispatchNavmeshLinkEdit: (update: NavmeshLinkUpdate) => void,
  navmeshNodeEdits: Record<string, NavmeshNodeEdit>,
  dispatchNavmeshNodeEdit: (update: NavmeshNodeUpdate) => void,
  navmeshLevelEdits: Record<string, NavmeshLevelEdit>,
  dispatchNavmeshLevelEdit: (update: NavmeshLevelUpdate) => void,
  navmeshAreaEdits: Record<string, NavmeshAreaEdit>,
  dispatchNavmeshAreaEdit: (update: NavmeshAreaUpdate) => void,
  navmeshUnitEdit: NavmeshUnitEdit,
  setNavmeshUnitEdit: (update: NavmeshUnitEdit) => void,
  setOverwritingNavmesh: (overwriting: boolean) => void,
  servieConfigEdits: Record<string, ServiceConfigEdit>,
  dispatchServiceConfigEdit: (update: ServiceConfigUpdate) => void,
  microfenceEdits: Record<string, MicrofenceEdit>,
  dispatchMircofenceEdit: (update: MicrofenceUpdate) => void,
  microfencePairEdits: Record<string, MicrofencePairEdit>,
  dispatchMircofencePairEdit: (update: MicrofencePairUpdate) => void,
  currentEdit: CurrentEdit | undefined,
  setCurrentEdit: (edit?: CurrentEdit | SetCurrentEditFn) => void,
  bulkEdit: BulkEdit | undefined,
  setBulkEdit: (edit?: BulkEdit) => void,
  currentDeletion: CurrentDeletion | undefined,
  setCurrentDeletion: (deletion?: CurrentDeletion) => void,
  applyNavmeshEdits: (original: Navmesh | Pick<Navmesh, 'clientId' | 'projectId'>) => FullOrPartialNavmesh | undefined,
  multiselectedNavmeshLinkIds: Record<number, boolean>,
  dispatchMultiselectNavmeshLinkId: (update: MultiselectNavmeshLink) => void,
  selectBoxMode: 'add' | 'subtract' | undefined,
};

const initalTool: ToolType = 'INFO';

export const EditingContext = createContext<Editing>({
  tool: initalTool,
  setTool: (_tool: ToolType) => VOID,
  locatedAssetEdits: {},
  dispatchLocatedAssetEdit: (_: LocatedAssetsUpdate) => VOID,
  portableAssetEdits: {},
  dispatchPortableAssetEdit: (_: PortableAssetsUpdate) => VOID,
  navmeshLinkEdits: {},
  dispatchNavmeshLinkEdit: (_: NavmeshLinkUpdate) => VOID,
  navmeshNodeEdits: {},
  dispatchNavmeshNodeEdit: (_: NavmeshNodeUpdate) => VOID,
  navmeshLevelEdits: {},
  dispatchNavmeshLevelEdit: (_: NavmeshLevelUpdate) => VOID,
  navmeshAreaEdits: {},
  dispatchNavmeshAreaEdit: (_: NavmeshAreaUpdate) => VOID,
  navmeshUnitEdit: undefined,
  setNavmeshUnitEdit: (_: NavmeshUnitEdit) => VOID,
  servieConfigEdits: {},
  dispatchServiceConfigEdit: (_: ServiceConfigUpdate) => VOID,
  setOverwritingNavmesh: (_: boolean) => VOID,
  microfenceEdits: {},
  dispatchMircofenceEdit: (_: MicrofenceUpdate) => VOID,
  microfencePairEdits: {},
  dispatchMircofencePairEdit: (_: MicrofencePairUpdate) => VOID,
  currentEdit: undefined,
  setCurrentEdit: (_edit?: CurrentEdit | SetCurrentEditFn) => VOID,
  bulkEdit: undefined,
  setBulkEdit: (_edit?: BulkEdit) => VOID,
  currentDeletion: undefined,
  setCurrentDeletion: (_deletion?: CurrentDeletion) => VOID,
  applyNavmeshEdits: (_: Navmesh | Pick<Navmesh, 'clientId' | 'projectId'>) => undefined,
  multiselectedNavmeshLinkIds: {},
  dispatchMultiselectNavmeshLinkId: (_: MultiselectNavmeshLink) => VOID,
  selectBoxMode: undefined,
});

export const EditingProvider = ({ children }: { children: ReactNode }) => {
  const [tool, setTool] = useState<ToolType>(initalTool);
  const [navLastUpdated, setNavLastUpdated] = useState<string | undefined>(undefined)
  const [locatedAssetEdits, dispatchLocatedAssetEdit] = useReducer((state: Record<string, LocatedAssetEdit>, update: LocatedAssetsUpdate) => {
    if ('clearAll' in update) return {};

    const newState = { ...state };
    const updates = Array.isArray(update) ? update : [update];
    updates.forEach(update => {
      newState[update.uuid] = 'revert' in update ? undefined : update
    });

    return newState
  }, {});
  const [portableAssetEdits, dispatchPortableAssetEdit] = useReducer((state: Record<string, PortableAssetEdit>, update: PortableAssetsUpdate) => {
    if ('clearAll' in update) return {};

    const newState = { ...state };
    const updates = Array.isArray(update) ? update : [update];
    updates.forEach(update => {
      newState[update.uuid] = 'revert' in update ? undefined : update
    });
    return newState
  }, {});
  const [navmeshLinkEdits, dispatchNavmeshLinkEdit] = useReducer((state: Record<number, NavmeshLinkEdit>, update: NavmeshLinkUpdate) => {
    setNavLastUpdated(new Date().toISOString())
    if ('clearAll' in update) return {};

    const newState = { ...state };
    const updates = Array.isArray(update) ? update : [update];
    updates.forEach(update => {
      newState[update.id] = 'revert' in update ? undefined : update
    });
    return newState;
  }, {})
  const [navmeshNodeEdits, dispatchNavmeshNodeEdit] = useReducer((state: Record<number, NavmeshNodeEdit>, update: NavmeshNodeUpdate) => {
    setNavLastUpdated(new Date().toISOString())
    if ('clearAll' in update) return {};

    const newState = { ...state };
    const updates = Array.isArray(update) ? update : [update];
    updates.forEach(update => {
      newState[update.id] = 'revert' in update ? undefined : update
    });
    return newState;
  }, {});
  const [navmeshLevelEdits, dispatchNavmeshLevelEdit] = useReducer((state: Record<number, NavmeshLevelEdit>, update: NavmeshLevelUpdate) => {
    setNavLastUpdated(new Date().toISOString())

    if ('clearAll' in update) return {};

    const newState = { ...state };
    const updates = Array.isArray(update) ? update : [update];
    updates.forEach(update => {
      newState[update.id] = 'revert' in update ? undefined : update
    });
    return newState;
  }, {});
  const [navmeshAreaEdits, dispatchNavmeshAreaEdit] = useReducer((state: Record<number, NavmeshAreaEdit>, update: NavmeshAreaUpdate) => {
    setNavLastUpdated(new Date().toISOString())

    if ('clearAll' in update) return {};

    const newState = { ...state };
    const updates = Array.isArray(update) ? update : [update];
    updates.forEach(update => {
      newState[update.id] = 'revert' in update ? undefined : update
    });
    return newState;
  }, {});
  const [navmeshUnitEdit, setNavmeshUnitEdit] = useState<NavmeshUnitEdit>()
  const [microfenceEdits, dispatchMircofenceEdit] = useReducer((state: Record<string, MicrofenceEdit>, update: MicrofenceUpdate) => {
    if ('clearAll' in update) return {};

    const newState = { ...state };
    const updates = Array.isArray(update) ? update : [update];
    updates.forEach(update => {
      newState[update.id] = 'revert' in update ? undefined : update
    });
    return newState
  }, {});
  const [microfencePairEdits, dispatchMircofencePairEdit] = useReducer((state: Record<string, MicrofencePairEdit>, update: MicrofencePairUpdate) => {
    if ('clearAll' in update) return {};

    const newState = { ...state };
    const updates = Array.isArray(update) ? update : [update];
    updates.forEach(update => {
      newState[update.id] = 'revert' in update ? undefined : update
    });
    return newState
  }, {});
  const [currentEdit, setCurrentEditState] = useState<CurrentEdit>();
  const [bulkEdit, setBulkEditState] = useState<BulkEdit>();

  const setCurrentEdit = (edit?: CurrentEdit | SetCurrentEditFn) => {
    setCurrentEditState(edit);
    if (edit) {
      setBulkEditState(undefined);
    }
  }
  const setBulkEdit = (edit?: BulkEdit) => {
    setBulkEditState(edit);
    if (edit) {
      setCurrentEditState(undefined);
    }
  }

  const [currentDeletion, setCurrentDeletion] = useState<CurrentDeletion>()
  const [overwritingNavmesh, setOverwritingNavmesh] = useState(false);

  const currentNavEdit = (currentEdit?.type === 'link' || currentEdit?.type === 'node' || currentEdit?.type === 'level' || currentEdit?.type === 'area') ? currentEdit : undefined;

  const applyNavmeshEdits = useCallback((original: Navmesh | Pick<Navmesh, 'clientId' | 'projectId'>) => {
    const originalLinks = ('links' in original && !overwritingNavmesh) ? (original.links ?? []) : [];
    const originalNodes = ('nodes' in original && !overwritingNavmesh) ? (original.nodes ?? []) : [];
    const originalLevels = ('levels' in original && !overwritingNavmesh) ? (original.levels ?? []) : [];
    const originalAreas = ('areas' in original && !overwritingNavmesh) ? (original.areas ?? []) : [];
    const originalUpdatedAt = ('updatedAt' in original && !overwritingNavmesh) ? original.updatedAt : undefined;
    const originalUnit = ('unit' in original && !overwritingNavmesh) ? (original.unit === 'ft' ? 'ft' : 'm') : undefined;

    const links = [
      ...originalLinks.filter(({ id }) => !navmeshLinkEdits[id] && (currentNavEdit?.type !== 'link' || id !== currentNavEdit.asset.id)),
      ...Object.values(navmeshLinkEdits).filter((link): link is NavmeshLink => !!link && !('delete' in link) && (currentNavEdit?.type !== 'link' || link.id !== currentNavEdit.asset.id)),
      ...[currentNavEdit?.type === 'link' ? currentNavEdit.asset : undefined].flatMap((n) => {
        if (!n) return [];
        const { uuid, ...rest } = n;
        return rest;
      })
    ].sort(({ id: a }, { id: b }) => a - b);
    const usedNodes: {[id: number]: true|undefined} = Object.fromEntries(links.flatMap(({a, b}) => [[a, true], [b, true]]));
    const nodes = [
      ...originalNodes.filter(({ id }) => !navmeshNodeEdits[id] && (currentNavEdit?.type !== 'node' || id !== currentNavEdit.asset.id)),
      ...Object.values(navmeshNodeEdits).filter((node): node is NavmeshNode => !!node && !('delete' in node) && (currentNavEdit?.type !== 'node' || node.id !== currentNavEdit.asset.id)),
      ...[currentNavEdit?.type === 'node' ? currentNavEdit.asset : undefined].flatMap((n) => {
        if (!n) return [];
        const { uuid, ...rest } = n;
        return rest;
      })
    ].filter(({id}) => usedNodes[id]);
    const levels = [
      ...originalLevels.filter(({ id }) => !navmeshLevelEdits[id] && (currentNavEdit?.type !== 'level' || id !== currentNavEdit.asset.id)),
      ...Object.values(navmeshLevelEdits).filter((level): level is NavmeshLevel => !!level && !('delete' in level) && (currentNavEdit?.type !== 'level' || level.id !== currentNavEdit.asset.id)),
      ...[currentNavEdit?.type === 'level' ? currentNavEdit.asset : undefined].flatMap((l) => {
        if (!l) return [];
        const { uuid, ...rest } = l;
        return rest;
      })
    ].sort(({ id: a }, { id: b }) => a - b);
    const areas = [
      ...originalAreas.filter(({ id }) => !navmeshAreaEdits[id] && (currentNavEdit?.type !== 'area' || id !== currentNavEdit.asset.id)),
      ...Object.values(navmeshAreaEdits).filter((area): area is NavmeshArea => !!area && !('delete' in area) && (currentNavEdit?.type !== 'area' || area.id !== currentNavEdit.asset.id)),
      ...[currentNavEdit?.type === 'area' ? currentNavEdit.asset : undefined].flatMap((a) => {
        if (!a) return [];
        const { uuid, ...rest } = a;
        return rest;
      })
    ].sort(({ id: a }, { id: b }) => a - b);
    const unit = navmeshUnitEdit ?? originalUnit;
    return { ...original, links, nodes, levels, areas, unit, updatedAt: navLastUpdated ?? originalUpdatedAt };
  }, [navmeshLinkEdits, navmeshNodeEdits, navmeshLevelEdits, navmeshAreaEdits, navmeshUnitEdit, currentNavEdit?.asset, currentNavEdit?.type, overwritingNavmesh, navLastUpdated]);

  const [servieConfigEdits, dispatchServiceConfigEdit] = useReducer((state: Record<string, ServiceConfigEdit>, update: ServiceConfigUpdate) => {
    if ('clearAll' in update) return {};

    return { ...state, [update.id]: 'revert' in update ? undefined : update }
  }, {});

  const [multiselectedNavmeshLinkIds, dispatchMultiselectNavmeshLinkId] = useReducer((state: Record<number, boolean>, update: MultiselectNavmeshLink) => {
    if ('clearAll' in update) {
      return {}
    }
    if ('ids' in update) {
      return {
        ...state,
        ...Object.fromEntries(update.ids.map(id => [id.toString(), update.operation === 'add']))
      }
    }
    return {
      ...state,
      [update.linkId]: !(state[update.linkId])
    };
  }, {});

  const [ctrlKeyDown, setCtrlKeyDown] = useState(false);
  const [shiftKeyDown, setShiftKeyDown] = useState(false);
  const selectBoxMode = (ctrlKeyDown && shiftKeyDown) ? 'subtract' : shiftKeyDown ? 'add' : undefined;

  useEffect(() => {
    const handleKeyDownForSelectbox = (e: KeyboardEvent) => {
      if (e.key === 'Control' || e.ctrlKey) {
        setCtrlKeyDown(true);
      }
      if (e.key === 'Shift') {
        setShiftKeyDown(true);
      }
    }
    const handleKeyUpForSelectbox = (e: KeyboardEvent) => {
      if (e.key === 'Control') {
        setCtrlKeyDown(false);
      } else if (e.key === 'Shift') {
        setShiftKeyDown(false);
      }
    }
    window.addEventListener("keydown", handleKeyDownForSelectbox);
    window.addEventListener("keyup", handleKeyUpForSelectbox);
    return () => {
      window.removeEventListener("keydown", handleKeyDownForSelectbox);
      window.removeEventListener("keyup", handleKeyUpForSelectbox)
    }
  }, [setCtrlKeyDown, setShiftKeyDown]);

  return (
    <EditingContext.Provider
      value={{
        tool,
        setTool,
        locatedAssetEdits,
        dispatchLocatedAssetEdit,
        portableAssetEdits,
        dispatchPortableAssetEdit,
        navmeshLinkEdits,
        dispatchNavmeshLinkEdit,
        navmeshNodeEdits,
        dispatchNavmeshNodeEdit,
        navmeshLevelEdits,
        dispatchNavmeshLevelEdit,
        navmeshAreaEdits,
        dispatchNavmeshAreaEdit,
        navmeshUnitEdit,
        setNavmeshUnitEdit,
        setOverwritingNavmesh,
        servieConfigEdits,
        dispatchServiceConfigEdit,
        microfenceEdits,
        dispatchMircofenceEdit,
        microfencePairEdits,
        dispatchMircofencePairEdit,
        currentEdit,
        setCurrentEdit,
        bulkEdit,
        setBulkEdit,
        currentDeletion,
        setCurrentDeletion,
        applyNavmeshEdits,
        multiselectedNavmeshLinkIds,
        dispatchMultiselectNavmeshLinkId,
        selectBoxMode,
      }}
    >
      {children}
    </EditingContext.Provider>
  );
};

export const useEditingData = () => useContext(EditingContext);
